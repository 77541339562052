
import { Options, Vue } from "vue-class-component";

@Options({
  data: () => ({
    /**
     * Used to determine if the application is in maintenance mode
     */
    isInMaintenanceMode: true,
  }),
  computed: {
    /**
     * Returns the translated message to display from the Vue i18n translation library.
     */
    message() {
      return this.$t("maintenance.message");
    },
  },
})
export default class Maintenance extends Vue {}
