import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c6fe7e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_font_awesome_icon, {
      icon: "wrench",
      size: "3x"
    }),
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t("maintenance.heading")), 1),
    _createElementVNode("p", { innerHTML: _ctx.message }, null, 8, _hoisted_1)
  ]))
}